import { lazy } from "react";
import "./App.css";
import AppThemeProvider from "AppThemeProvider";
import { Icon, IconButton } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { notistackRef } from "constants/RefConstants";
import Suspense from "common/Suspense";
import useAuthUser from "hooks/useAuthUser";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

function App() {
  const authUser = useAuthUser();
  const client = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
    channelProfile: "live",
    role: "host"
  });
  return (
    <AppThemeProvider>
            <AgoraRTCProvider client={client}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        preventDuplicate
        action={(key) => (
          <IconButton
            onClick={() => {
              notistackRef.current.closeSnackbar(key);
            }}
            color="inherit"
            size="small"
          >
            <Icon>close</Icon>
          </IconButton>
        )}
      >
        <Suspense>
          {!!authUser?.accessToken ? <AppProtected /> : <AppPublic />}
        </Suspense>
      </SnackbarProvider>
      </LocalizationProvider>
    
          </AgoraRTCProvider>
    </AppThemeProvider>
  );
}

export default App;

const AppPublic = lazy(() => import("./AppPublic"));
const AppProtected = lazy(() => import("./AppProtected"));
